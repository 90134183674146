import { FC, useState, useEffect } from "react"
import React, { useGlobal } from "reactn"
import Img, { ImgProps } from "../components/Img"
import Link from "../components/Link"
import cn from "../utils/cn"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import styled from "styled-components"

interface HeaderLinkProps {
  to: string,
  className?: string
}

const HeaderLink: FC<HeaderLinkProps> = ({ className, ...props }) => (
  <Link
    className={cn("font-secondary inline-block mr-8 py-4 xl:py-1 lg:mr-0 lg:my-2", className)}
    {...props}
  />
)

interface HeaderNavProps {
  showNav: boolean
}

const HeaderNav = styled.nav`
  ${(props: HeaderNavProps) => props.showNav ? `
    display: flex;
  ` : null}
`

interface PageHeaderProps {
  logo: {
    to: string,
    img: ImgProps
  },
  nav: {
    to: string,
    text: string,
    shown: boolean
  }[]
}

const PageHeader: FC<PageHeaderProps> = ({ logo, nav }) => {
  const { 1: setShowContactDialog } = useGlobal<any>("showContactDialog")
  const [ showNav, setShowNav ] = useState(false)
  const [ scrolled, setScrolled ] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setShowNav(false);

    const handleScroll = () => { 
      if (window.pageYOffset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ location.pathname ])

  return (
    <header className={"sticky top-0 z-10 text-light bg-blur flex items-center justify-between xl:py-3 " + (scrolled ? 'bg-dark' : 'backdrop-none') + ' ' + (showNav ? 'header-navigation-visible' : 'header-navigation-hidden')}>
      <div className="header-inner">
        <div className="flex items-center justify-between lg:w-full z-10">
          <Link
            to={logo.to}
            className="hover:opacity-50"
          >
            <Img
              className="w-40"
              src={logo.img.src}
              alt={logo.img.alt}
              loading={'eager'}
              fadeIn={false}
            />
          </Link>

          <button
            className="hidden lg:flex p-2 text-light items-center text-sm"
            onClick={() => { setShowNav(!showNav) }}
            id="header-menu-button"
          >
            <div className="pt-2 pb-2 w-8 h-8 flex flex-col justify-between">
              {showNav ? (
                <div
                  className="text-center text-2xl "
                  style={{ lineHeight: 0.9 }}
                >
                  ×
                </div>
              ) : (
                <>
                  <hr className="mx-auto h-px pt-px bg-white rounded mb-px w-full" />
                  <hr className="mx-auto h-px pt-px bg-white rounded my-1 w-full" />
                  <hr className="mx-auto h-px pt-px bg-white rounded mt-px w-full" />
                </>
              )}
            </div>
          </button>
        </div>

        <HeaderNav
          role="navigation"
          id="header-navigation"
          className="xl:flex xl:flex-wrap xl:justify-center xl:items-center lg:flex-col"
          showNav={showNav}
        >
          {nav.filter(({
            shown
          }) => shown).map(({
            to,
            text
          }) => (
            <HeaderLink
              key={to}
              to={to}
            >
              {text}
            </HeaderLink>
          ))}

          <a
            className="-ml-1 btn-ghost border-current hover:border-white hover:bg-white hover:text-black px-3 pt-1 pb-px sm:mt-1 tracking-normal normal-case font-secondary text-base"
            href="/ansatte"
          >
            Kontakt oss
          </a>
        </HeaderNav>
      </div>
    </header>
  )
}

export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query PageHeader {
        markdownRemark(
          frontmatter: {
            component: {
              eq: "PageHeader"
            }
          }
        ) {
          frontmatter {
            nav {
              to
              text
              shown
            }
            logo {
              to
              img {
                src {
                  childImageSharp {
                    fluid(maxWidth: 200 quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    `
  )

  const {
    nav,
    logo
  } = markdownRemark.frontmatter

  return (
    <PageHeader
      nav={nav}
      logo={logo}
    />
  )
}

import React, { FC, useState } from "react"
import groupBy from "lodash/groupBy"
import styled from "styled-components"

const lookup = (v: string, s: string): boolean => (
  v.toLowerCase().includes(s.toLowerCase())
)

export interface IndexEmployee {
  fullName: string,
  position: string,
  email: string,
  number: string,
  department: string
}

interface EmployeesIndexProps {
  employees: IndexEmployee[]
}

const Employee = styled.div`
  @media(min-width: 640px) {
    height: 6rem;
    &:hover {
      .name { display: none; }
      .contact { display: flex; }
    }
  }
`

const EmployeesIndex: FC<EmployeesIndexProps> = ({
  employees
}) => {
  const [ search, setSearch ] = useState("")

  const filtered = employees.filter(
    (employee) => lookup(`${employee.position} ${employee.fullName}`, search)
  )

  const departments = groupBy(filtered, "department")

  return (
    <div className="bg-black text-white">
      <input
        className="px-6 py-3 w-full bg-gray-900 text-white text-center border-4 border-solid border-black"
        value={search}
        onChange={(e) => { setSearch(e.target.value) }}
        placeholder="Søk etter en stilling eller ansatt.."
      />

      <div
        className="flex lg:flex-wrap"
        style={{ minHeight: 500 }}
      >
        {Object.keys(departments).map((department, index) => (
          <div
            key={index}
            className="text-center w-full"
          >
            <h2 className="border-b-2 border-gray-900 border-solid font-semibold text-lg py-6 lg:py-3">
              {department}
            </h2>

            <div className="flex flex-col lg:flex-row lg:flex-wrap">
              {departments[department].map(({
                fullName,
                position,
                email,
                number
              }, index) => (
                <Employee
                  key={index}
                  className="flex justify-center flex-col lg:w-1/3 md:w-1/2 sm:px-3 sm:py-4 sm:w-full sm:flex-row sm:justify-between xs:px-2"
                >
                  <div className="name sm:flex-col sm:text-left">
                    <h3 className="font-semibold">
                      {fullName}
                    </h3>

                    <div className="text-sm mt-1">
                      {position}
                    </div>
                  </div>

                  <div className="contact text-sm hidden flex-col items-center sm:flex sm:items-end sm:text-right">
                    <a
                      href={`mailto:${email}`}
                      className="inline-block text-white hover:text-gray-500"
                    >
                      {email}
                    </a>

                    <a
                      href={`tel://${number}`}
                      className="inline-block text-white hover:text-gray-500 mt-1"
                    >
                      {number}
                    </a>
                  </div>
                </Employee>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EmployeesIndex

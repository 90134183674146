import { FC, useState } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import Meta, { MetaProps } from "../components/Meta"
import EmployeesShowcase, { ShowcaseEmployee } from "../components/EmployeesShowcase"
import EmployeesIndex, { IndexEmployee } from "../components/EmployeesIndex"
import GdprDialog from "../components/GdprDialog"
import Input from "../components/Input"
import { useForm } from "react-hook-form"
import config from "../config"
import { toast } from "react-toastify"

interface AnsattePageTemplateProps {
  showcase: ShowcaseEmployee[],
  index: IndexEmployee[]
}

const AnsattePageTemplate: FC<AnsattePageTemplateProps> = ({
  showcase,
  index
}) => (
  <main
    id="index-page"
    className="border-b-2 border-gray-900 border-solid"
  >
    <EmployeesShowcase
      employees={showcase}
    />

    <EmployeesIndex
      employees={index}
    />
  </main>
)

interface AnsattePageProps {
  data: {
    markdownRemark: {
      frontmatter: AnsattePageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const AnsattePage: React.FC<AnsattePageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ showGdprDialog, setShowGdprDialog ] = useState<boolean>(false)
  const [ formData, setFormData ] = useState<FormData>()

  const submit = async () => {
    setIsLoading(true)
    try {
      const res = await fetch(`${config.apiUrl}/hovedsider/inquiries`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "Noe gikk galt med innsending av skjemaet. Vennligst kontakt oss via e-post eller telefon isteden.")
      }

      toast.success("Takk for din henvendelse. Vi tar kontakt med deg så snart som mulig")
      setShowGdprDialog(false)
    } catch(e: any) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  const onSubmit = (data: FormData): void => {
    setFormData(data)
    setShowGdprDialog(true)
  }
  
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <div id="contact-us">
        <div id="contact-us-info" className="box-section bg-white">
          <div className="flex-col items-start text-black p-10 w-1/2 md:w-full">
            <header className="entry-header">
              <h1 className="title">Kontakt</h1>
            </header>
            <div className="details">
              <p><strong>Bonum AS</strong><br/> Org.nr - 917 504 121</p>
              <p>Besøksadresse:<br/>Parkveien 37, 0258 Oslo</p>
              <p><strong>Telefon: <a href="tel:22232385" className="phone">222 32 385</a></strong></p>
            </div>
          </div>
          <iframe className="flex-1 w-1/2 md:w-full" src="https://maps.google.com/maps?q=Parkveien%2037,%20Oslo%20&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no"></iframe>
        </div>
        <div id="contact-us-form">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="text-sm text-white"
          >
            <div className="px-6">
              <Input
                name="firstName"
                label="Fornavn"
                placeholder="Skriv inn ditt fornavn"
                className="mb-5 block"
                required={true}
                register={register}
                errors={errors}
              />

              <Input
                name="lastName"
                label="Etternavn"
                placeholder="Skriv inn ditt etternavn"
                className="mb-5 block"
                required={true}
                register={register}
                errors={errors}
              />

              <Input
                name="email"
                label="E-post"
                placeholder="Skriv inn din e-post addresse"
                className="mb-5 block"
                required={true}
                register={register}
                errors={errors}
              />

              <Input
                name="phone"
                label="Telefonnummer"
                placeholder="Skriv inn ditt telefonnummer"
                className="mb-5 block"
                required={false}
                register={register}
                errors={errors}
              />

              <Input
                type="textarea"
                name="message"
                label="Melding"
                placeholder="Skriv inn en melding"
                required={true}
                register={register}
                errors={errors}
              />
            </div>

            <div className="border-t border-solid border-gray-900 p-6 mt-6">
              <button
                type="submit"
                className="btn btn-primary w-full block"
              >
                Ta kontakt
              </button>
            </div>
          </form>
        </div>
      </div>

      <GdprDialog
        shown={showGdprDialog}
        onAccept={submit}
        onDeny={() => setShowGdprDialog(false)}
        isLoading={isLoading}
        description="Dette skjemaet samler inn ditt navn, din e-post adresse, og telefonnummer (valgfritt), slik at vi kan kontakte deg når vi behandler henvendelsen."
        consents={[{
          question: "Jeg samtykker til at Bonum kan samle på informasjon i skjemaet.",
          required: true
        }]}
      />
    </>
  )
}

export default AnsattePage

export const AnsattePageQuery = graphql`
  query AnsattePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        showcase {
          fullName
          position
          description
          quote
          tmbImg {
            src
            alt
          }
          fullImg {
            src
            alt
          }
        }

        index {
          fullName
          position
          email
          number
          department
        }

      }
    }
  }
`
